$bg-grey: #F5F5F5;
$first-height-breakpoint: rem-calc(815);
p {
  font-size: rem-calc(20);
  font-weight: lighter;
}

h1,
h2 {
  font-weight: lighter;
  text-align: center;
}

.black-button {
  border: none;
  color: white;
  font-size: rem-calc(15);
  font-weight: 500;
  border: rem-calc(1) solid black;
  background-color: black;
  padding: rem-calc(15) rem-calc(45) rem-calc(15) rem-calc(45);
  text-align: center;
  transition: all 0.3s;
  position: relative;
  &:hover {
    color: black;
    background-color: transparent;
  }
  &.in {
    &:before {
      font-size: rem-calc(25);
      content: "\00BB";
      position: absolute;
      top: rem-calc(5);
      left: rem-calc(15);
    }
    &:after {
      font-size: rem-calc(25);
      content: "\00AB";
      position: absolute;
      top: rem-calc(5);
      right: rem-calc(15);
    }
  }
  &.out {
    &:before {
      font-size: rem-calc(25);
      content: "\00AB";
      position: absolute;
      top: rem-calc(5);
      left: rem-calc(15);
    }
    &:after {
      font-size: rem-calc(25);
      content: "\00BB";
      position: absolute;
      top: rem-calc(5);
      right: rem-calc(15);
    }
  }
}

.owl-carousel {
  position: static;
}

.owl-nav {
  .owl-prev {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: rem-calc(60);
    @media screen and (max-width: rem-calc(1780)) {
      left: rem-calc(10);
    }
    &:after {
      content: url('../img/navigation/prev.png');
      background-color: white;
      width: rem-calc(35);
      height: rem-calc(64);
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .owl-next {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: rem-calc(60);
    @media screen and (max-width: rem-calc(1780)) {
      right: rem-calc(10);
    }
    &:after {
      content: url('../img/navigation/next.png');
      background-color: white;
      width: rem-calc(35);
      height: rem-calc(64);
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

@mixin grey-block {
  section {
    &.grey-block {
      width: 100%; //background-color: $bg-grey;
      //background: linear-gradient(top, rgb(245,245,245), rgb(0,0,0));
      background: linear-gradient(to bottom, rgba(245, 245, 245, 0.3), rgba(0, 0, 0, 0.1));
      &.plain-text {
        min-height: rem-calc(300);
        display: flex;
        justify-content: center;
        align-items: center;
        .content-container {
          margin: rem-calc(10);
          max-width: $global-width;
          p {
            text-align: center;
          }
        }
      }
      .btn-container {
        padding-top: rem-calc(20);
        padding-bottom: rem-calc(30);
        text-align: center;
      }
    }
  }
}

@mixin references {
  .references {
    h2 {
      margin: rem-calc(50) rem-calc(10) rem-calc(47) rem-calc(10);
    }
    .button-container {
      text-align: center;
      margin: rem-calc(45) rem-calc(10) rem-calc(45) rem-calc(10);
    }
    .grid-x {
      .cell {
        display: flex;
        justify-content: center;
        align-items: center;
        height: rem-calc(300);
        .content-container {
          width: 98%;
          height: 98%;
          background-color: $bg-grey;
          position: relative;
          .reference-image {
            width: 100%;
            height: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
          }
          .reference-detail {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.8);
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 0;
            transition: all 0.3s;
            &:hover {
              opacity: 1;
            }
            .content-container {
              display: block;
              width: auto;
              height: auto;
              span {
                width: 100%;
                text-align: center;
                display: block;
                color: white;
                &.druh {
                  font-size: rem-calc(20);
                  font-weight: lighter;
                }
                &.nazev {
                  font-size: rem-calc(20);
                  font-weight: 900;
                }
              }
              background-color: transparent;
              .ref-button {
                padding: rem-calc(10) rem-calc(28) rem-calc(10) rem-calc(28);
                border: rem-calc(1) solid white;
                color: white;
                position: relative;
                margin-top: rem-calc(12);
                display: block;
                text-align: center;
                font-weight: 500;
                text-align: center;
                transition: all 0.3s;
                &:before {
                  position: absolute;
                  display: block;
                  content: "\00AB";
                  top: rem-calc(5);
                  left: rem-calc(8);
                  width: rem-calc(15);
                  height: rem-calc(15);
                  font-size: rem-calc(20);
                }
                &:after {
                  position: absolute;
                  display: block;
                  content: "\00BB";
                  top: rem-calc(5);
                  right: rem-calc(8);
                  width: rem-calc(15);
                  height: rem-calc(15);
                  font-size: rem-calc(20);
                }
                &:hover {
                  background-color: rgba(255, 255, 255, 0.8);
                  border: rem-calc(1) solid rgba(255, 255, 255, 0.8);
                  color: black;
                }
              }
            }
          }
        }
      }
    }
  }
}

@mixin carousel {
  section.co-rikaji {
    position: relative;
    h2 {
      margin-top: rem-calc(30);
      margin-bottom: rem-calc(40);
    }
    .owl-container {
      max-width: rem-calc(1700);
      padding: 0 rem-calc(70) rem-calc(50) rem-calc(70);
      margin: auto;
      width: 100%;
      .klient {
        display: flex;
        h3 {
          font-size: rem-calc(25);
          font-family: 'Roboto Condensed', sans-serif;
          img {
            display: inline;
            width: auto;
            height: auto;
          }
        }
        p {
          font-size: rem-calc(20);
          color: #828282;
        }
        @media screen and (max-width: rem-calc(620)) {
          flex-direction: column;
          .img-container img {
            width: 60%;
            display: block;
            margin: auto;
          }
          .text-container {
            width: 100% !important;
            padding-left: 0 !important;
            h3 {
              width: 100%;
              text-align: center;
            }
            p {
              text-align: center;
              font-weight: lighter;
            }
            strong {
              display: block;
              width: 100%;
              text-align: center;
              font-weight: normal;
            }
          }
        }
        div {
          &.text-container {
            padding-left: rem-calc(20);
            font-size: rem-calc(16);
            width: 65%;
            p {
              padding-bottom: none;
              margin-bottom: none;
            }
          }
        }
      }
    }
  }
}

#stickanchor {
  height: 0;
  width: 100%;
}

.shoppingcart {
  @media screen and (max-width: rem-calc(1023)) {
    margin-left: rem-calc(15);
  }
  &.empty {
    display: none;
  }
  &:hover {
    .cart {
      box-shadow: 0 0 rem-calc(5) #60B196;
    }
  }
  .cart {
    width: rem-calc(40);
    height: rem-calc(40);
    background-color: #60B196;
    color: white;
    text-align: center;
    padding-top: rem-calc(8);
    transition: all 0.3s;
  }
}

.hidden {
  display: none;
}

@media screen and (max-width: 1370px) {
  html {
    font-size: 75%;
  }
}

@media screen and (max-width: 1025px) {
  html {
    font-size: 100%;
  }
}

#orderModal{
    max-width: rem-calc(840);
    border: 5px solid black;
    width: 90%;
    @media screen and (max-width: 640px){
      width: 100%;
    }
  h2{
    font-family: 'Roboto', sans-serif;
    font-weight: lighter;
    text-align: center;
    font-size: rem-calc(40);
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .btns{
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 680px;
    margin: 0 auto 40px auto;
    @media screen and (max-width: 640px){
      flex-direction: column;
    }
    .button{
      background-color: white;
      border: 1px solid black;
      color: black;
      padding: 0;
      margin: 0;
      height: 50px;
      font-size: 20px;
      text-align: center;
      line-height: 45px;
      width: 100%;
      max-width: 290px;
      @media screen and (max-width: 640px){
        margin-bottom: 20px;
      }
      &.mall{
        background-color: #3cb496;
        color: white;
        border: 1px solid #3cb496;
        font-size: 25px;
        font-weight: bold;
        line-height: 49px;
        text-transform: uppercase;
        position: relative;
        &:before{
          content: '';
          width: 23px;
          height: 21px;
          display: block;
          position: absolute;
          top: 13px;
          left: 15px;
          background-image: url(../img/tricko_detail/arrow-right.svg);
          background-size: cover;
        }
        &:after{
          content: '';
          width: 23px;
          height: 21px;
          display: block;
          position: absolute;
          top: 13px;
          right: 15px;
          background-image: url(../img/tricko_detail/arrow-left.svg);
          background-size: cover;
        }
      }
    }
  }
}

#orderSuccessModal{
  h3, h2{
    font-family: 'Roboto', sans-serif;
    font-weight: lighter;
    text-align: center;
    font-size: rem-calc(30);
    margin-top: 40px;
    margin-bottom: 20px;
  }
  h3{
    font-size: rem-calc(20);
    margin-top: 0;
    margin-bottom: 40px;
  }
}