.cool-tricka{
    @include grey-block;
    nav{
        padding: rem-calc(30) rem-calc(10) rem-calc(30) rem-calc(10);
        ul{
            li{
                width: auto;
                &.selected{
                    a{
                        color: $primary-color;
                    }
                }
                a{
                    font-family: 'Roboto Condensed', sans-serif;
                    font-size: rem-calc(20);
                    width: auto;
                    font-weight: lighter;
                    color: #AAAAAA;
                    transition: all 0.3s;
                    &:hover{
                        color: $primary-color;
                    }
                }
            }
        }
        &.mobile-filter {
            margin: 0 rem-calc(10) 0 rem-calc(10);
            ul {
                li {
                    margin: 0;
                    &.is-submenu-item {
                        a:after {
                            content: " ";
                            position: absolute;
                            display: block;
                            bottom: rem-calc(-1);
                            height: rem-calc(2);
                            width: 30%;
                            border-bottom: rem-calc(1) dashed black;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }
                }
                a {
                    font-weight: normal;
                    font-size: rem-calc(16);
                    padding: rem-calc(20) 0 rem-calc(20) 0;
                    position: relative;
                    &.accordion-title{
                        &:before{
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .btn-container{
        display: block;
        margin: auto;
    }
    .grid-container{
        max-width: rem-calc(1900);
        .cell{
            h2{
                font-size: rem-calc(30);
                font-weight: lighter;
                font-family: 'Roboto', sans-serif;
            }
            .img-container{
                width: 100%;
                max-width: rem-calc(360);
                border: rem-calc(1) solid #6F6F6F;
                margin: auto;
                box-sizing: border-box;
                overflow: hidden;
                transition: all 0.3s;
                img{
                    width: 100%;
                    transition: all 0.3s;
                }
                &:hover{
                    box-shadow: 0 0 rem-calc(5) black;
                }
            }
            
            .colors{
                width: 100%;
                padding-bottom: rem-calc(10);
                display: flex;
                align-items: center;
                justify-content: center;
                .color{
                    width: rem-calc(30);
                    height: rem-calc(30);
                    box-sizing: border-box;
                    border: rem-calc(1) solid black;
                    margin: 0 rem-calc(5) 0 rem-calc(5);
                }
            }
            span{
                width: 100%;
                display: block;
                text-align: center;
                font-family: 'Roboto Condensed', sans-serif;
                font-weight: lighter;
                font-size: rem-calc(30);
            }
        }
    }
}