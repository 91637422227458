div.sluzby-stranka {
    .sluzby-navigation {
        min-height: 100px;
        margin: 25px 0 25px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        nav.sluzby-seznam {
            font-size: 25px;
            font-family: 'Roboto Condensed', sans-serif;
            a {
                transition: all 0.3s;
                &:hover {
                    font-weight: normal;
                    color: white;
                    background-color: $primary-color;
                }
            }
            @media screen and (max-width: 1160px) {
                li {
                    margin: 0;
                }
            }
        }
        .mobile-filter {
            margin: 0 rem-calc(10) 0 rem-calc(10);
            width: 100%;
            ul {
                li {
                    margin: 0;
                    &.is-submenu-item {
                        a:after {
                            content: " ";
                            position: absolute;
                            display: block;
                            bottom: rem-calc(-1);
                            height: rem-calc(2);
                            width: 30%;
                            border-bottom: rem-calc(1) dashed black;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }
                }
                a {
                    font-weight: normal;
                    font-size: rem-calc(16);
                    padding: rem-calc(20) 0 rem-calc(20) 0;
                    position: relative;
                    &.accordion-title {
                        &:before {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    section.vsechny-sluzby {
        article {
            padding: 40px 0 20px 0;
            &:nth-child(odd){
                background-color: #F7F7F7;
            }
            &:nth-child(even){
                background-color: white;
            }
            h1 {
                font-size: 37px;
                font-weight: lighter;
                text-align: center;
                margin-bottom: 15px;
            }
            p {
                font-size: 20px;
                text-align: justify;
                &.lead {
                    font-weight: bold;
                    text-align: center;
                }
            }
            .list-container{
                background-color: #EBEBEB;
                width: 100%;
                height: 100%;
                padding: 30px;
                h2{
                    font-size: 25px;
                    font-weight: bold;
                    text-align: left;
                }
                ul{
                    li{
                        font-size: 20px;
                        padding-top: 20px;
                        font-weight: 500;
                    }
                }
            }
            .img-container{
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .black-button{
                    display: block;
                    width: 100%;
                    margin-top: 15px;
                    @media screen and (max-width: 640px){
                        &:after{
                            top: 50%;
                            transform: translateY(-50%);
                        }
                        &:before{
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                }
            }
        }

        .grid-container{
            max-width: 1350px;
            .grid-x{
                .cell{
                    padding: 10px 35px 10px 35px;
                }
            }
        }
    }
}