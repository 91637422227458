.objednavka{
    @include grey-block;
    .grey-block{
        padding-top: rem-calc(30);
        padding-bottom: rem-calc(30);
    }

    .order-list{
        max-width: rem-calc(1600);
        margin: auto;
        padding: 0 rem-calc(15) 0 rem-calc(15);

        .extra-padding{
            padding-right: rem-calc(30);
            @media screen and (max-width: rem-calc(640)){
                padding-right: 0;
            }
        }

        .more-padding{
            padding-right: rem-calc(10);
            @media screen and (max-width: rem-calc(640)){
                padding-right: 0;
            }
        }
        
        h2{
            font-size: rem-calc(30);
            font-weight: 900;
            font-family: 'Roboto', sans-serif;
            text-align: left;
            padding-top: rem-calc(15);
        }

        .product-line{
            border-top: rem-calc(1) solid black;
            padding: rem-calc(20) 0 rem-calc(40) 0;

            /* Styly nadpisů*/
            h4{
                font-family: 'Roboto Condensed', sans-serif;
                font-size: rem-calc(25);
            }


            .delete-product{
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                img{
                    width: rem-calc(25);
                    height: rem-calc(25);
                    margin: 0 rem-calc(20) 0 rem-calc(20);
                }
            }
            .text-container{
                padding-left: rem-calc(30);
                h3{
                    font-size: rem-calc(40);
                    font-weight: lighter;
                    font-family: 'Roboto Condensed', sans-serif;
                    margin-bottom: rem-calc(0);
                }
                p{
                    font-size: rem-calc(20);
                    font-weight: lighter;
                    color: #1C1C1C;
                    margin-bottom: rem-calc(2);
                }
                .color{
                    width: rem-calc(40);
                    height: rem-calc(40);
                    box-sizing: border-box;
                    border: rem-calc(1) solid black;
                }
            }
            .pocet-kusu{
                padding-left: rem-calc(50);
                padding-top: rem-calc(20);
                input{
                    width: rem-calc(136);
                    height: rem-calc(40);
                    font-size: rem-calc(30);
                    font-family: 'Roboto', sans-serif;
                    font-weight: 900;
                    margin-bottom: rem-calc(10);
                    box-sizing: border-box;
                    border: rem-calc(1) solid black;
                }
                span{
                    font-size: rem-calc(15);
                    font-weight: lighter;
                }
            }
            .line-price{
                padding-left: rem-calc(50);
                padding-top: rem-calc(20);
                h4{
                    margin-bottom: 0;
                }
                span{
                    font-size: rem-calc(40);
                    font-weight: 900;
                }
            }

            @media screen and (max-width: rem-calc(1515)){
                .line-price{
                    padding-left: rem-calc(10);
                }
                .pocet-kusu{
                    padding-left: rem-calc(10);
                }
                .text-container{
                    padding-left: rem-calc(10);
                }
            }
        }
    }

    .form-half{
        width: 100%;
        height: 100%;
        background-color: #F5F5F5;
        padding: rem-calc(15);
        &.first{
            margin-right: rem-calc(10);
            @media acreen and (max-width: rem-calc(640)){
                margin-right: 0;
            }
        }
        h3{
            font-size: rem-calc(30);
            font-family: 'Roboto Condensed', sans-serif;
            font-weight: lighter;
        }
        label{
            font-size: rem-calc(15);
            font-family: 'Roboto Condensed', sans-serif;
            input{
                width: 100%;
                height: rem-calc(30);
                border: none;
                background-color: white;
                font-family: 'Roboto', sans-serif;
                font-size: rem-calc(15);
                font-weight: lighter;
                padding: rem-calc(5);
            }
            input[type="radio"]{
                width: auto;
                margin-bottom: 0;
                margin-right: rem-calc(10);
                display: inline-block;
            }
            textarea{
                margin-top: rem-calc(20);
                width: 100%;
                height: rem-calc(92);
                padding: rem-calc(5);
                font-family: 'Roboto', sans-serif;
                font-size: rem-calc(12);
                font-weight: lighter;
                resize: none;
                border: none;
                &.no-top-margin{
                    margin-top: 0;
                }
            }
            span{
                position: relative;
                top: rem-calc(-10);
            }
        }
        span.udaje{
            font-size: rem-calc(12);
            font-weight: lighter;
            color: #666666;
            cursor: pointer;
            display: block;
            margin-bottom: rem-calc(10);
        }

        .celkova-cena-napis{
            font-family: 'Roboto Condensed', sans-serif;
            font-size: rem-calc(15);
            padding-top: rem-calc(20);
            display: block;
        }

        .celkova-cena{
            font-size: rem-calc(40);
            font-family: 'Roboto', sans-serif;
            font-weight: 900;
            line-height: rem-calc(40);
        }
        .note{
            font-size: rem-calc(12);
            font-weight: lighter;
        }
    }

    input[type="submit"]{
        display: block;
        width: 100%;
        margin: rem-calc(10) 0 0 0;
        cursor: pointer;
        padding: 0;
        font-family: 'Roboto', sans-serif;
        font-size: rem-calc(30);
        font-weight: 900;
        text-transform: uppercase;
        text-align: center;
        border: none;
        background-color: #3BB395;
        color: white;
        height: rem-calc(50);
    }

    .agree{
        font-size: rem-calc(15);
        font-weight: lighter;
        text-align: center;
        display: block;
        width: 100%;
        padding-top: rem-calc(10);
        a{
            font-weight: 900;
            text-decoration: underline;
        }
    }
}