footer{
    section.contact-me{
        min-height: rem-calc(590);
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        //background-color: $bg-grey;
        background-image: url(../img/footer/background.png);
        background-size: cover;
        .contact-container{
          min-height: rem-calc(420);
          max-width: rem-calc(1250);
          width: 100%;
          background-color: white;
          margin: rem-calc(10) rem-calc(10) rem-calc(10) rem-calc(10);
          border: rem-calc(2) solid #5A5A5A;
          h2{
            margin-top: rem-calc(20);
            margin-bottom: rem-calc(45);
          }
          .form-container{
            max-width: rem-calc(1000);
            margin: 0 auto rem-calc(20) auto;
            padding: 0 rem-calc(10) 0 rem-calc(10);
            label{
              margin: 0 rem-calc(60) rem-calc(20) rem-calc(60);
              height: rem-calc(60);
              position: relative;
              &:before{
                display: block;
                position: absolute;
                width: rem-calc(60);
                height: rem-calc(60);
                top: 0;
                left: rem-calc(-60);
                background-color: black;
                // Změna na background
                content: " ";
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
              }
    
              &:first-child:before{
                //content: url(../img/form/jmeno.png);
                background-image: url(../img/form/jmeno.png);
              }
    
              &:nth-child(2):before{
                //content: url(../img/form/email.png);
                background-image: url(../img/form/email.png);
              }
              &:last-child:before{
                //content: url(../img/form/telefon.png);
                background-image: url(../img/form/telefon.png);
              }
    
              @media screen and (max-width: rem-calc(1024)){
                margin-right: 0;
                input{
                  max-width: unset !important;
                }
              }
    
              input{
                height: rem-calc(60);
                max-width: rem-calc(270);
                width: 100%;
                box-sizing: border-box;
                border: rem-calc(1) solid black;
                margin: 0;
                position: relative;
                &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                  color: black;
                }
                &::-moz-placeholder { /* Firefox 19+ */
                  color: black;
                }
                &:-ms-input-placeholder { /* IE 10+ */
                  color: black;
                }
                &:-moz-placeholder { /* Firefox 18- */
                  color: black;
                }
              }
            }
            textarea{
              width: 100%;
              min-height: rem-calc(140);
              resize: none;
              box-sizing: border-box;
              border: rem-calc(1) solid black;
              padding-top: rem-calc(15);
              padding-left: rem-calc(15);
              &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color: black;
              }
              &::-moz-placeholder { /* Firefox 19+ */
                color: black;
              }
              &:-ms-input-placeholder { /* IE 10+ */
                color: black;
              }
              &:-moz-placeholder { /* Firefox 18- */
                color: black;
              }
            }
            .black-button{
              float: right;
            }
          }
        }
      }
      .contact-stripe{
          width: 100%;
          min-height: rem-calc(150);
          background-color: #A9A9A9;
          /*display: flex;
          justify-content: space-around;
          align-items: center;*/
          .grid-x{
            align-items: center;
            justify-content: space-around;
            height: 100%;
            min-height: rem-calc(150);
            @media screen and (max-width: rem-calc(640)){
              justify-content: center;
            }
            .cell{
              text-align: center;
            }
          }
      }
      #map{
          width: 100%;
          height: rem-calc(400);
      }
      .bottom-stripe{
          height: rem-calc(100);
          width: 100%;
          background-color: #1B1B1B;
          display: flex;
          justify-content: center;
          align-items: center;
          span{
              color: white;
              padding-left: rem-calc(20);
          }
      }
}