.detail-reference {
    section.heading {
        max-width: $global-width;
        margin: auto;
        text-align: center;
        margin: rem-calc(50) auto rem-calc(50) auto;
        padding: 0 rem-calc(10) 0 rem-calc(10);
    }
    section.gallery {
        .grid-container {
            max-width: 956x;
            width: 100%;
            img {
                width: 100%;
                padding: rem-calc(10);
            }
        }
    }
    nav.reference-navigation {
        padding-top: rem-calc(30);
        padding-bottom: rem-calc(40);
        .grid-container {
            max-width: rem-calc(1270);
            .cell {
                cursor: pointer;
                &.disabled{
                    font-weight: lighter;
                    cursor: not-allowed;
                }

                font-weight: bold;
                img {
                    margin-bottom: 0;
                }
                &:nth-child(2){
                    img{
                        margin-bottom: rem-calc(2);
                    }
                }
            }
        }
    }
}