.tricko-detail {
    .mobile-colors-heading{
        font-size: 1.5625rem;
        font-family: 'Roboto Condensed', sans-serif;
        font-weight: normal;
        color: #1c1c1c;
        text-align: center;
        margin-bottom: 16px;
    }
    .mobile-colors{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        .color-container{
            padding: 8px;
        }
        .color{
            width: 2.5rem;
            height: 2.5rem;
            cursor: pointer;
            border: 1px solid $black;
        }
    }
    .grid-y {
        height: 100%;
        margin-right: rem-calc(10);
        .cell {
            img {
                width: 100%;
                max-width: rem-calc(136);
            }
        }
    }
    .mobile-images{
        img{
            display: block;
            margin: auto;
        }
    }
    padding-top: rem-calc(40);
    img.detail-img {
        display: block;
        margin: auto;
    }

    input[type="radio"]:checked + label span.color-option {
        border: rem-calc(3) solid black;
    }

    .color-option{
        display: block;
        width: rem-calc(40);
        height: rem-calc(40);
        margin-right: rem-calc(10);
        box-sizing: border-box;
        border: rem-calc(1) solid black;
        transition: box-shadow 0.3s;
        &:hover{
            box-shadow: 0 0 rem-calc(5) black;
        }
    }
    .text-container{
        padding: rem-calc(20);
        h1{
            font-size: rem-calc(40);
            font-weight: lighter;
            width: 100%;
            text-align: left;
            //margin-bottom: 0;
        }
        span.cena{
            font-size: rem-calc(80);
            font-weight: 900;
            line-height: rem-calc(80);
            display: block;
            padding-bottom: rem-calc(5);
        }
        button{
            cursor: pointer;
        }
        .black-button{
            background-color: #3BB395;
            font-weight: bold;
            font-size: rem-calc(25);
            border: rem-calc(1) solid #3BB395;
            padding: rem-calc(5) rem-calc(45) rem-calc(5) rem-calc(45);
            text-transform: uppercase;
            cursor: pointer;
            &:before{
                font-size: rem-calc(40);
                top: rem-calc(-8);
            }
            &:after{
                font-size: rem-calc(40);
                top: rem-calc(-8);
            }
            &:hover{
                color: #3BB395;
                background-color: white;
            }
            @media screen and (max-width: rem-calc(340)){
                font-size: rem-calc(18);
                &:after{
                    top: rem-calc(-17);
                }
                &:before{
                    top: rem-calc(-17);
                }
            } 
        }

        p{
            font-size: rem-calc(15);
            font-family: 'Roboto Condensed', sans-serif;
            color: #666666;
        }

        .form-container{
            margin-top: rem-calc(20);
            input, select{
                max-width: rem-calc(280);
            }
            label{
                font-size: rem-calc(25);
                font-family: 'Roboto Condensed', sans-serif;
                font-weight: normal;
                input{
                    font-size: rem-calc(20);
                    font-weight: lighter;
                    font-family: 'Roboto', sans-serif;
                }
            }
            .fake-label{
                margin-top: rem-calc(20);
                .fake-label-heading{
                    font-size: rem-calc(25);
                    font-family: 'Roboto Condensed', sans-serif;
                    font-weight: normal;
                    .selected-color-option{
                        color: #666666;
                    }
                }

                label{
                    margin: 0 rem-calc(5) 0 rem-calc(5);
                }

                .ikonka-muz{
                    display: block;
                    width: rem-calc(40);
                    height: rem-calc(40);
                    background-image: url(../img/tricka/muz-ikonka.png);
                    background-color: black;
                    background-size: cover;
                    background-position: center;
                }

                .ikonka-zena{
                    display: block;
                    width: rem-calc(40);
                    height: rem-calc(40);
                    background-image: url(../img/tricka/zena-ikonka.png);
                    background-color: black;
                    background-size: cover;
                    background-position: center;
                }

                input[type="radio"]:checked + label span.ikonka-muz {
                    background-image: url(../img/tricka/muz-ikonka-on.png);
                }

                input[type="radio"]:checked + label span.ikonka-zena {
                    background-image: url(../img/tricka/zena-ikonka-on.png);
                }

                input[type="radio"]:checked + label span.size-option {
                    color: white;
                    background-color: black;
                    border: rem-calc(1) solid black;
                }

                .size-option{
                    width: rem-calc(40);
                    height: rem-calc(40);
                    display: block;
                    border: rem-calc(1) solid white;
                    transition: all 0.3s;
                    box-sizing: border-box;
                    text-align: center;
                    cursor: pointer;
                    font-size: rem-calc(20);
                    font-weight: lighter;
                    font-family: 'Roboto', sans-serif;
                    &:hover{
                        border: rem-calc(1) solid black;
                    }

                }
                .tabulka{
                    font-family: 'Roboto', sans-serif;
                    font-weight: lighter;
                    color: #666666;
                    font-size: 15px;
                    position: relative;
                    padding-left: 20px;
                    margin-top: 16px;
                    display: block;
                    &:before{
                        content: '';
                        display: block;
                        position: absolute;
                        height: 16px;
                        width: 16px;
                        top: 2px;
                        left: 0;
                        background-image: url(../img/tabulka-velikosti.svg);
                        background-size: cover;
                        background-repeat: no-repeat;
                    }
                }
            }
        }
    }
    nav.reference-navigation {
        padding-top: rem-calc(30);
        padding-bottom: rem-calc(40);
        .grid-container {
            max-width: rem-calc(1270);
            .cell {
                cursor: pointer;
                &.disabled{
                    font-weight: lighter;
                    cursor: not-allowed;
                }

                font-weight: bold;
                img {
                    margin-bottom: 0;
                }
                &:nth-child(2){
                    img{
                        margin-bottom: rem-calc(2);
                    }
                }
            }
        }
    }
}

#order-shirt-mobile{
    display: none;
    margin-top: 20px;
    height: 70px;
    width: 100%;
    &:after, &:before{
        top: 50%;
        transform: translateY(-50%);
    }
    @media screen and (max-width: 640px){
        display: block;
    }
}

#addToCartButton{
    @media screen and (max-width: 640px){
        opacity: 0;
        position: absolute;
        width: 0;
        height: 0;
    }
}