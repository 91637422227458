#offCanvas-menu{
  .logo-container{
    width: 100%;
    img{
      display: block;
      width: 50%;
      margin: rem-calc(10) auto rem-calc(10) auto;
    }
  }
  ul li{
    a{
      color: black;
      padding: rem-calc(20) 0 rem-calc(20) rem-calc(20);
      position: relative;
      &:after{
        content: " ";
        display: block;
        position: absolute;
        bottom: 0;
        left: rem-calc(20);
        width: 30%;
        border-bottom: rem-calc(1) dashed black;
      }
      //border-bottom: rem-calc(1) solid rgb(50,50,50);
    }
  }
}
