.references {
    @include grey-block;
    @include references;
    hr{
        width: 100%;
        margin: 0;
        padding: 0;
        border: none;
        height: rem-calc(1);
        background-color: $primary-color;
        max-width: none;
      }
    nav {
        padding: rem-calc(20) 0 rem-calc(20) 0;
        ul li {
            img {
                height: rem-calc(43);
            }
        }
        &.mobile-filter {
            margin: 0 rem-calc(10) 0 rem-calc(10);
            ul {
                li {
                    margin: 0;
                    &.is-submenu-item {
                        a:after {
                            content: " ";
                            position: absolute;
                            display: block;
                            bottom: rem-calc(-1);
                            height: rem-calc(2);
                            width: 30%;
                            border-bottom: rem-calc(1) dashed black;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }
                }
                a {
                    font-weight: normal;
                    font-size: rem-calc(16);
                    padding: rem-calc(20) 0 rem-calc(20) 0;
                    position: relative;
                    &.accordion-title{
                        &:before{
                            display: none;
                        }
                    }
                }
            }
        }
    }
    @include carousel;
}