.homepage{
  @include grey-block;
  @include references;
  hr{
    width: 100%;
    margin: 0;
    padding: 0;
    border: none;
    height: rem-calc(1);
    background-color: $primary-color;
    max-width: none;
  }
  section.sluzby{

    h2{
      margin-top: rem-calc(30);
      margin-bottom: rem-calc(40);
    }
    .grid-container{
      max-width: rem-calc(1900);
    }
    .content-container{
      width: 96%;
      margin: 0 auto rem-calc(40) auto;
      h3{
        width: 100%;
        text-align: center;
        font-family: 'Roboto Condensed', sans-serif;
        font-size: rem-calc(32);
        //font-weight: lighter;
        text-transform: lowercase;
      }
      img{
        height: rem-calc(116);
        margin: 0 auto rem-calc(20) auto;
        display: block;
      }
      p{
        font-size: rem-calc(20);
        font-weight: lighter;
        width: 100%;
        margin: 0;
        padding: 0;
        text-align: center;
      }

      a.black-button{
        padding: rem-calc(8) 0 rem-calc(8) 0;
        width: 100%;
        max-width: rem-calc(200);
        display: block;
        margin: rem-calc(30) auto 0 auto;
        &:before{
          top: rem-calc(-1);
        }
        &:after{
          top: rem-calc(-1);
        }
      }

      .show-reference{
        width: 100%;
        text-align: center;
        font-size: rem-calc(16);
        font-weight: 500;
        display: block;
        margin-top: rem-calc(8);
        color: $black;
      }
    }
  }

  section.brandcloud{
    padding-top: rem-calc(70);
    padding-bottom: rem-calc(70);
    .grid-container{
      .monitor{
        overflow: hidden;
        img{
          transition: all 0.3s;
        }
        &:hover{
          img{
            transform: scale(1.01);
          }
        }
      }
      max-width: rem-calc(1700);
      h1{
        text-align: left;
      }
      p{
        margin-bottom: rem-calc(30);
        &.bold-text{
          font-weight: bold;
          margin-bottom: rem-calc(60);
        }
      }
    }
  }

  section.predstaveni{
    background-color: $bg-grey;
    min-height: rem-calc(600);
    .grid-container{
      max-width: rem-calc(1800);
      padding-top: rem-calc(90);
      .cell{
        .img-container{
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          img{
            max-width: rem-calc(600);
            width: 100%;
            height: auto;
            padding-bottom: rem-calc(30);
          }
        }

        .text-container{
          padding: 0 rem-calc(30) 0 rem-calc(50);
          h2{
            text-align: left;
            font-size: rem-calc(37);
            font-weight: lighter;
            line-height: rem-calc(35);
          }
  
          p{
            font-size: rem-calc(20);
            font-weight: lighter;
          }
          @media screen and (max-width: rem-calc(1250)){
            p{
              font-size: rem-calc(16);
            }
            padding: 0 rem-calc(10) rem-calc(30) rem-calc(10);
          }
          @media screen and (max-width: rem-calc(640)){
            padding-top: rem-calc(30);
          }
        }

        .timeline{
          .item{
            height: rem-calc(82);
            font-size: rem-calc(30);
            padding-left: rem-calc(10);
            @media screen and (max-width: rem-calc(350)){
              font-size: rem-calc(20) !important;
              &:after{
                display: none !important;
              }
              span{
                padding-left: rem-calc(10) !important;
              }
            }
            span{
              padding-left: rem-calc(20);
              position: relative;
              transition: all 0.3s;
              cursor: pointer;
              @media screen and (max-width: rem-calc(1250)){
                top: rem-calc(-2);
              }
              &:hover{
                padding-left: rem-calc(25);
              }
            }
            &:before{
              content: "";
              border: rem-calc(2) solid black;
              border-radius: 100%;
              display: inline-block;
              height: rem-calc(20);
              width: rem-calc(20);
            }
            &:after{
              content: "";
              display: block;
              height: rem-calc(40);
              border-left: rem-calc(3) solid black;
              position: relative;
              left: rem-calc(8);
            }

            &.last:after{
              display: none;
            }

            @media screen and (max-width: rem-calc(1320)){
              font-size: rem-calc(23);
            }
            @media screen and (max-width: rem-calc(1055)){
              font-size: rem-calc(21);
            }
            @media screen and (max-width: rem-calc(670)){
              font-size: rem-calc(19);
            }
          }
        }
      }
    }
  }

  @include carousel;
}
