@charset 'utf-8';

@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,400i,700|Roboto:300,400,400i,500,700,900&subset=latin-ext');

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
//@include foundation-grid;
// @include foundation-flex-grid;
@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
// @include foundation-progress-element;
// @include foundation-progress-element;
// @include foundation-meter-element;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
// @include foundation-float-classes;
@include foundation-flex-classes;

// @include foundation-prototype-classes;

@include motion-ui-transitions;
@include motion-ui-animations;

/* Components */
@import "components/mysettings";
@import "components/offcanvas";
@import "components/welcome";
@import "components/navigation";
@import "components/footer";

/* Pages */
@import "pages/homepage";
@import "pages/references";
@import "pages/detail-reference";
@import "pages/cool-tricka";
@import "pages/tricko_detail";
@import "pages/objednavka";
@import "pages/sluzby";