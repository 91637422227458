nav{
    img{
      display: block;
      margin: 0 auto rem-calc(9) auto;
    }
    ul{
      li{
        margin-left: rem-calc(15);
        margin-right: rem-calc(15);
        a{
          color: $body-font-color;
          text-align: center;
          //font-weight: bold;
          font-family: 'Roboto Condensed', sans-serif;
          font-size: rem-calc(25);
          width: rem-calc(161);
          img{
            transition: all 0.3s ease 0s;
            position: relative;
            top: 0;
            height: rem-calc(40);
          }
          &:hover{
            //color: black;
            font-weight: bold;
            img{
              top: rem-calc(-4);
            }
          }
        }
      }
    }
  &.second{
    ul li a{
      font-size: rem-calc(20);
      width: rem-calc(136);
      @media screen and (max-width: rem-calc(1250)){
        font-size: initial;
        width: rem-calc(100);
        padding-left: 0;
        padding-right: 0;
      }
      @media screen and (max-width: rem-calc(1040)){
        width: rem-calc(95);
      }
      &:hover{
        font-weight: normal;
      }
    }
  }
  &.reference-switcher{
    a{
      width: auto;
      font-size: rem-calc(20);
      font-family: 'Roboto Condensed', sans-serif;
      color: #AAAAAA;
      &.selected{
        color: $black;
      }
      &:hover{
        font-weight: normal;
      }
    }
  }
  &.mobile-filter{
    a{
      width: 100%;
    }
  }
}
header.main{
  .black-bar{
    min-height: rem-calc(45);
    background-color: black;
    color: white;
    a{
      color: white;
    }
    .cell{
      margin: rem-calc(8) 0 rem-calc(8) 0;
      text-align: center;
    }
    @media screen and (max-width: rem-calc(640)){
      display: none;
    }
  }

  .white-bar{
    box-shadow: 0 rem-calc(2) rem-calc(5) rgba(0,0,0,0.5);
    height: rem-calc(120);
    background-color: white;
    @media screen and (max-width: rem-calc(640)){
      height: rem-calc(60);
    }
    .grid-container{
      height: 100%;
      .grid-x{
        height: 100%;
        .cell{
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .resp-menu img{
            width: rem-calc(35);
          }
        }
      }
    }
    .logo{
      width: 100%;
      max-width: rem-calc(297);
      @media screen and (max-width: rem-calc(640)){
        max-width: rem-calc(150);
      }
    }
  }
}
