#welcome{
  width: 100%;
  height: 100vh;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  .content-container{
    img.logo{
      max-width: rem-calc(335);
      width: 100%;
      height: auto;
      margin: auto;
      display: block;
      margin-bottom: rem-calc(88);
      padding: 0 rem-calc(10) 0 rem-calc(10);
    }
    nav.first{
      margin-bottom: rem-calc(88);
    }

    .img-center{
      display: block;
      margin: auto;
      padding: 0 rem-calc(10) 0 rem-calc(10);
      &.first{
        margin-bottom: rem-calc(100);
      }
      &#arrow-down{
        cursor: pointer;
        transition: all 0.3s ease 0s;
        position: relative;
        top: 0;
        max-width: rem-calc(90);
        &:hover{
          top: rem-calc(4);
        }
      }
    }
  }

  @media screen and (max-height: rem-calc(815)){
    nav.first{
      margin-bottom: rem-calc(44) !important;
    }
    img.logo{
      margin-bottom: rem-calc(44) !important;
    }
    .img-center.first{
      margin-bottom: rem-calc(50) !important;
    }
  }

  @media screen and (max-height: rem-calc(680)){
    img.logo{
      width: auto !important;
      height: rem-calc(200) !important;
    }
  }

  @media screen and (max-height: rem-calc(550)){
    nav.first{
      display: none !important;
    }
    img.logo{
      width: auto !important;
      height: rem-calc(100) !important;
    }
  }

}
